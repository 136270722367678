import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [5,2];

export const dictionary = {
		"/(marketing)": [34,[5]],
		"/(marketing)/__features": [~35,[5]],
		"/(marketing)/__method": [~36,[5]],
		"/(marketing)/about": [37,[5]],
		"/(app)/app": [~6,[2]],
		"/(app)/app/feedback": [7,[2]],
		"/(app)/app/feedback/[slug]": [8,[2]],
		"/(app)/app/guide": [9,[2,3]],
		"/(app)/app/guide/code-reviews": [10,[2,3]],
		"/(app)/app/guide/code-reviews/actionbar": [11,[2,3]],
		"/(app)/app/guide/code-reviews/discussions": [12,[2,3]],
		"/(app)/app/guide/code-reviews/files": [13,[2,3]],
		"/(app)/app/guide/code-reviews/merging": [14,[2,3]],
		"/(app)/app/guide/code-reviews/participants": [15,[2,3]],
		"/(app)/app/guide/code-reviews/reviews": [16,[2,3]],
		"/(app)/app/guide/code-reviews/revisions": [17,[2,3]],
		"/(app)/app/guide/getting-started": [18,[2,3]],
		"/(app)/app/guide/pull-requests/creating": [19,[2,3]],
		"/(app)/app/guide/pull-requests/list": [20,[2,3]],
		"/(app)/app/guide/settings/repositories": [21,[2,3]],
		"/(app)/app/guide/settings/teammates": [22,[2,3]],
		"/(app)/app/prs": [23,[2]],
		"/(app)/app/prs/[platform]/[owner]/[repo]/[id=integer]": [~25,[2]],
		"/(app)/app/prs/[...rest]": [24,[2]],
		"/(app)/app/repos": [26,[2]],
		"/(app)/app/settings": [27,[2]],
		"/(app)/app/settings/[owner]": [28,[2,4]],
		"/(app)/app/settings/[owner]/billing": [29,[2,4]],
		"/(app)/app/settings/[owner]/install": [30,[2,4]],
		"/(app)/app/settings/[owner]/repos": [31,[2,4]],
		"/(app)/app/settings/[owner]/settings": [32,[2,4]],
		"/(app)/app/settings/[owner]/team": [33,[2,4]],
		"/(marketing)/contact": [38,[5]],
		"/(marketing)/error": [39,[5]],
		"/(marketing)/legal/cookies": [40,[5]],
		"/(marketing)/legal/privacy": [41,[5]],
		"/(marketing)/legal/terms": [42,[5]],
		"/(marketing)/pricing": [43,[5]],
		"/(marketing)/signin": [~44,[5]],
		"/(marketing)/signout": [~45,[5]],
		"/(marketing)/support/faq": [46,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';