import {
    PUBLIC_SENTRY_ENABLED,
    PUBLIC_SENTRY_ENVIRONMENT,
    PUBLIC_SENTRY_SAMPLE_RATE,
    PUBLIC_SENTRY_URL
} from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';

if (PUBLIC_SENTRY_ENABLED) {
    Sentry.init({
        dsn: PUBLIC_SENTRY_URL,
        tracesSampleRate: 1.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: parseFloat(PUBLIC_SENTRY_SAMPLE_RATE),

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        environment: PUBLIC_SENTRY_ENVIRONMENT || 'production'

        // If you don't want to use Session Replay, just remove the line below:
        //   integrations: [new Replay()],
    });
}
// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
